.m-t-10{margin-top: 10px;}
.m-l-10{margin-left: 10px;}
.flex{display: flex;}
.m-t-3{margin-top: 3px;}
.mobile_input {
  padding-left: 65px;
}
.dropdown_input {
  display: block;
  line-height: 35px;
  padding-left: 10px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  width: 100%;
  height: 36px;
  padding-right: 10px;
}
.watsapp_check{
  width: 24px;
  height: 24px;
  font-size: 5px;
  /* position: relative;
  top: -56px; */
}

@media (max-width: 1200px) {
  .w_input {
    width: 100%;
  }
}

/* @media (max-width: 767px) {
}

@media (max-width: 574px) {
}

@media (max-width: 576px) {
} */
