.analyse_all {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input_flx {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
}
.w_input {
  width: 48%;
}

.kg_div {
  position: relative;
}
@media (max-width: 1300px) {
  .row_div {
    display: block;
  }
}

@media (max-width: 1200px) {
  .popup-container_analysis {
    height: unset;
    padding: 20px 0px;
  }

  .w_input {
    width: 100%;
  }

  .popup_analysis {
    width: 80%;
  }

  .Cuedwell_para {
    width: 80%;
  }
}
@media (max-width: 992px) {
  @media (max-width: 767px) {
    .popup {
      width: 92%;
    }
    .baby_img {
      display: none;
    }

    .card_wrap {
      margin-top: 20px;
    }
    .w_50 {
      width: 100%;
    }

    .Cuedwell_para {
      width: 100%;
    }
  }

  @media (max-width: 574px) {
    .form_flx {
      display: block;
    }

    .popup_analysis {
      width: 94%;
    }

    .cmn_gap {
      padding: 10px 15px;
    }
  }
}
