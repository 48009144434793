#clickBtn {
  background-color: rebeccapurple;
  outline: none;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 1.5rem;
  color: #ffffff;
  margin: 30px;
  border-radius: 20px;
  box-shadow: 1px 6px 12px 0px rgb(0 0 0 / 13%);
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}
#clickBtn:hover {
  background-color: orange;
}
#clickBtn_share,
#clickBtn_analysis,
#clickforgot {
  outline: none;
  border: none;

  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

#popup {
  display: block;
}
#popup_share,
#popup_analysis {
  display: block;
}
.popup-container_social,
.popup-container_analysis {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: rgb(96 95 127 / 70%);
  position: absolute;
  top: 0;
  left: 0;
}

.label_gender {
  color: #637381;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
}
.popup-btn {
  display: inline-block;

  margin: 10px 0px;
  transition: 0.2s all ease-in;
  display: inline-flex;
  padding: 11px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;

  border: transparent;
  cursor: pointer;
}
.bg_cancel {
  background: #f4f6fa;
  color: #000;
}
.bg_analyze {
  background-color: #00a86b;
  color: #fff;
}

#customers {
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  padding: 12px 20px;
  white-space: nowrap;
}
.value_title {
  color: #546670;
}
.border_top {
  border-top: 1px solid #f6f6f6;
}
.overflow_ta {
  overflow-y: scroll;
  height: 226px;
}
.overflow_scll {
  overflow-x: scroll;
}
.overflow_scll::-webkit-scrollbar {
  display: none;
}
.overflow_ta::-webkit-scrollbar {
  display: none;
}

#customers th {
  padding: 12px 20px;
  text-align: left;
  background: #f9f9fb;
  color: #000;
}
.frame_img {
  position: relative;
  top: 3px;
  right: 4px;
}
.age_div {
  display: inline-block;
  margin: 10px 0px;
  transition: 0.2s all ease-in;
  display: inline-flex;
  padding: 11px 15px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border: transparent;
  cursor: pointer;
}
.gender_img {
  position: relative;
  top: 2px;
}
.age_bg {
  border-radius: 8px 0px 0px 8px;
  background: #f5f6f7;
}
.gender_bg {
  border-radius: 0px 8px 8px 0px;
  background: #f1faff;
}
.age_title {
  color: #434343;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
}
.week_title {
  color: #00a86b;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 420;
  line-height: 21px;
  width: 50%;
}
@media (max-width: 1200px) {
  .popup_analysis {
    width: 80%;
  }
  .popup-container {
    height: unset;
    width: 100%;
    padding: 20px 0px;
  }
  .popup_analysis {
    width: 80%;
  }
  .popup-container_analysis {
    height: unset;
    padding: 20px 0px;
  }
  .popup_social {
    width: 44%;
  }
}
@media (max-width: 574px) {
  .popup_analysis {
    width: 94%;
  }
  .popup_social {
    width: 80%;
  }
  .guindance_flx {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 100%;
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .popup_social {
    width: 60%;
  }
  .popup {
    width: 92%;
  }
}
.guindance_flx {
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
}

.guindance_img img {
  margin: 20px 0px 5px;
  display: inline-block;
  width: 100%;
  height: 160px;
  border-radius: 20px;
  object-fit: cover;
}
@media (max-width: 820px) {
  .guindance_flx {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 50% 50%;
    justify-content: space-between;
  }
}
@media (max-width: 574px) {
  .popup_analysis {
    width: 94%;
  }

  .popup_social {
    width: 80%;
  }

  .guindance_flx {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 100%;
    justify-content: space-between;
  }
}
