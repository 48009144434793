#customers {
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  padding: 12px 20px;
  white-space: nowrap;
}
.value_title {
  color: #546670;
}
.border_top {
  border-top: 1px solid #f6f6f6;
}
.overflow_scll {
  overflow-x: scroll;
}
.overflow_scll::-webkit-scrollbar {
  display: none;
}

#customers th {
  padding: 12px 20px;
  text-align: left;
  background: #f9f9fb;
  color: #000;
}

.footer-col {
  width: 31%;
  padding: 0 15px;
}
.follow_div {
  color: #fff;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
  display: inline-block;
  margin: 10px 0px;
}
.fa-whatsapp {
  color: #03905d;
}

.journey_div {
  color: #c2c2c2;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin: 10px 0px;
}

#popup {
  display: none;
}
#popup_share,
#popup_analysis {
  display: none;
}
.popup-container_social,
.popup-container_analysis {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: rgb(96 95 127 / 70%);
  position: absolute;
  top: 0;
  left: 0;
}
.popup-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: rgb(96 95 127 / 70%);
  position: absolute;
  top: 0;
  left: 0;
}
.popup {
  border-radius: 20px;
  background: #fff;
  padding: 20px 28px;
  width: 50%;
}

.popup_social {
  border-radius: 20px;
  background: #fff;
  padding: 20px 28px;
  width: 25%;
}
.popup_analysis {
  border-radius: 20px;
  background: #fff;
  padding: 20px 28px;
  width: 50%;
}

.popup > h2 {
  font-size: 1.6rem;
  margin-bottom: 10px;
}
.popup > p {
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.confirm_star {
  color: #ef2323;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  display: inline-block;
  margin-bottom: 10px;
}

.bg_cancel {
  background: #f4f6fa;
  color: #000;
}
.bg_analyze {
  background-color: #00a86b;
  color: #fff;
}

.analyse_all {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-select {
  position: relative;
  height: 35px;
  cursor: pointer;
}

.dropdown-select span.selection {
  display: block;
  line-height: 35px;
  padding-left: 10px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
}

.dropdown-select ul {
  list-style: none;
  margin: 0 0 0 0;
  padding: 0;
}

.input_flx {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
}
.w_input {
  width: 48%;
}
g_div {
  position: relative;
}

.mobile_span {
  position: absolute;
  border-radius: 0px 7px 7px 0px;
  background: #f4f6fa;
  display: inline-block;
  padding: 6.2px 13px;
  top: 2.1px;
  left: 2px;
  color: #4c4c4c;
  font-family: Lato;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
.mobile_input {
  padding-left: 65px;
}

.cmn_gap {
  padding: 10px 60px;
}
.img_flx,
.btn_div {
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.main_container {
  margin: 0 auto;
  max-width: 1366px;
}

.img_flx {
  cursor: pointer;
}
.nav_flx {
  display: flex;
  justify-content: space-between;
}
.request_btn {
  cursor: pointer;
  padding: 11px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #00a86b;
  border: 0;
  color: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  align-content: left;
}
.search_img {
  width: 17px;
  height: 17px;
  flex-shrink: 0;
  margin-right: 50px;
}
.gap_btn {
  column-gap: 30px;
}
.public_div {
  max-width: 698px;
  margin: 40px auto 10px auto;
  text-align: center;
}

.row_div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 80px 0px 60px 0px;
}
.col_6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.team_img {
  position: relative;
}
.team_img img {
  max-width: 100%;
  display: block;
  object-fit: cover;
}
.team_txt {
  border-radius: 40px 0px 0px 0px;
  background: #f2faff;
  padding: 20px;
  margin-top: -106px;
  margin-left: 92px;
  display: inline-block;
  z-index: 999999;
  position: relative;
}
.work_para {
  color: #546670;

  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.work_txt {
  color: #000;
  font-family: Lato;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 150% */
}

.stroke_one {
  color: #fff;
  font-family: Lato;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 72px;
  -webkit-text-stroke: 1px #c6c6c6;
  text-shadow: -2px -2px 0 #c6c6c6, 2px -2px 0 #c6c6c6, -2px 2px 0 #c6c6c6,
    2px 2px 0 #c6c6c6;
}
.circle_img {
  width: 70px;
  height: 70px;
  background-color: #f5f6f7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.circle_all {
  display: flex;
  column-gap: 40px;
  row-gap: 60px;
  align-items: center;
}
.circle_flx {
  display: flex;
  flex-direction: column;

  row-gap: 83px;
  margin-left: 40px;
}

.submit_inquiry {
  color: #000;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  width: 60%;
  line-height: 28px; /* 150% */
}
.circle_img::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 210px;
  border-left: 2px dashed #555;
  top: 13px;
  left: 34px;
  z-index: -2;
  bottom: 0;
}
.content_none::before {
  content: none;
}

.health_para {
  color: #000;
  font-size: 34px;
  font-style: normal;
  width: 100%;
  font-weight: 650;
  line-height: 45px; /* 150% */
  text-align: center;
}
.team_para {
  margin: 10px 0px 30px 0px;
  color: #546670;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-align: center;
}
.person_img {
  /* background-image: url("../assets/img/shade\ icon.png"); */
  margin: 40px auto 10px auto;
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  /* background-size: 100% 100%; */
  /* opacity: 0.75;
background: linear-gradient(266deg, #062638 55.9%, rgba(255, 255, 255, 0.15) 78.99%); */
}

.w_50 {
  width: 100%;
}
.community_para {
  color: #000;
  font-size: 40px;
  font-style: normal;
  width: 100%;
  font-weight: 600;
  line-height: 50px; /* 150% */
  text-align: center;
}
.text_center >p{
  text-align: center;

}

.Cuedwell_para {
  color: #546670;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  width: 70%;
  margin-top: 10px;
}
.margin_gap {
  margin-top: 10px;
}
.service_gap {
  margin: 50px 0px 50px 0px;
  display: flex;
}
.card_content {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 10px;
}
.lorem_para {
  color: #546670;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.heart_img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.card_wrap {
  display: flex;
  column-gap: 60px;
}
.mt_card {
  margin: 60px 0px;
}
.global_challenge {
  background: #062638;
  padding: 50px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.global_txt {
  color: #00a1ff;

  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 150% */
  width: 27%;
}
.notes_img {
  background-color: #fff;
  border-radius: 8px;
  background: #fff;
  width: 82.692px;
  height: 82.692px;
  flex-shrink: 0;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notes_img img {
  max-width: 100%;
}
.notes_details {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  align-items: center;
}
.note_number {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}
.note_txt {
  color: #f1f1f1;
  text-align: center;

  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px; /* 150% */
}

.footer {
  background: #001623;
  padding: 50px 0 20px;
}
.footer_all {
  margin-top: 45px;
  border-top: 1px solid #f6f6f6;
  color: #c2c2c2;
  text-align: center;
  padding: 20px 20px 0px;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

ul {
  list-style: none;
}

.footer-col {
  padding: 0 15px;
}

.footer-col h4 {
  font-size: 18px;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}
.footer-col ul li a {
  color: #c2c2c2;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.col_3 {
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  align-items: flex-end;
}
.col_3 h4 {
  position: relative;
  left: -233px;
}
.col_2 h4 {
  font-size: 18px;

  position: relative;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}
.footer-col svg {
  position: relative;
  top: 6px;
}
.col_3 svg {
  position: relative;
  top: 6px;
  left: -3px;
  width: 18px;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 14px;
}

.footer-col ul li a {
  color: #ddd;
  display: block;
  font-size: 1rem;
  font-weight: 300;
  text-transform: capitalize;
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer-col ul li a:hover {
  color: #fff;
  padding-left: 7px;
}

.footer-col .social-links a {
  color: #fff;
  background-color: #fff;
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  margin: 0 14px 10px 0;
  line-height: 40px;
  transition: all 0.5s ease;
}
.facebook_icn {
  color: #4267b2;
}
.linkedin_icn {
  color: #0077b5;
}
.insta_icn {
  color: #f9ce34;
}

.footer-col .social-links a:hover {
  color: #151515;
  background-color: #fff;
}
.success_stories {
  background: #fffbe9;
  padding: 40px 50px;
}
.success_title {
  text-align: center;
  display: block;
  color: #000;

  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 150% */
}
.arw_width {
  width: 56px;
  height: 56px;
  background-color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arw_2 {
  position: relative;
  left: 20px;
}
.arw_1 {
  transform: rotate(180deg);
  position: relative;
  left: -20px;
}
.ipsum_txt {
  color: #000;

  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  margin: 10px 0px;
  line-height: 48px; /* 150% */
}
.partners_div {
  border-radius: 16px;
  background: #f5f6f7;
  padding: 25px 30px;
  max-width: 779px;
  margin: 80px auto 40px;
}
.sloware_img {
  width: 186px;
  height: 55px;
  flex-shrink: 0;
  border-left: 2px solid #c6c6c6;
  padding-left: 30px;
}
.skybox_img {
  width: 161px;
  height: 56px;
  flex-shrink: 0;
}
.partner_flx {
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.partner_txt {
  color: #00a1ff;
}
.partner_div_txt {
  font-family: Lato;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}
.sed_txt {
  color: #546670;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 150% */
}
.mt_btn {
  margin-top: 60px;
}
.lorem_para_all {
  width: 56%;
}
.baby_img {
  width: 402px;
  height: 379px;
  flex-shrink: 0;
}
.baby_img img {
  max-width: 100%;
}
.d_flx {
  column-gap: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faq_div {
  color: #000;

  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 150% */
}

.border_btm {
  border-bottom: 1px solid #f6f6f6;
  padding-bottom: 10px;
}
.social-links {
  display: flex;
  flex-wrap: nowrap;
}
.mt_cmn {
  margin-top: 30px;
}
.accordion-header {
  background-color: #f7f7f7;

  color: #fff; /* White text */
  padding: 10px 0px;

  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0 0;

  color: black;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}

.accordion-content {
  color: #546670;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  overflow: hidden;

  max-height: 0;
  transition: max-height 0.3s ease;
}

.accordion-content p {
  line-height: 1.5;
}

.icon {
  transition: transform 0.3s ease;
}

.social_all {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin: 13px 0px;
}

.social_media_icn {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00a1ff0f;
}
.tabs {
  margin: 30px 0;
}
.tabs-tags {
  display: flex;
  gap: 1px;
  border-bottom: 1px solid #0000001c;
}
.tabs-tags__tag {
  padding: 10px 0;
  width: 100%;
  color: #000;

  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: background-color 0.2s;
  position: relative;
  cursor: pointer;
  padding-left: 30px;
}

.tabs-tags__tag.active::before {
  content: "";
  position: absolute;
  top: -35px;
  border-radius: 2px 2px 0px 0px;
  width: 2px;
  left: 46px;
  height: 152px;
  background-color: #00a86b;
  transform: rotate(90deg);
}
.footer_h {
  height: 130px;
}
.submit_home {
  color: #546670;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
.submit_title {
  color: #000;
  font-family: Lato;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px; /* 150% */
}
.submit_div {
  background: #d8f1ff;
  padding: 50px 30px;
}
.tabs-content__text {
  padding: 20px 30px;
}
.details_span {
  color: #546670;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.m_input {
  margin: 20px 0px 70px 0px;
}
.form_btn {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #d9d9d9;
}
.form_para {
  color: #00a1ff;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.form_span {
  color: #000;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.form_flx {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
}
.footer_para {
  color: #c2c2c2;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  text-align: center;
  margin-bottom: 20px;
}
.rectangle-placeholder {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: grey;
}

@media (max-width: 1300px) {
  .row_div {
    display: block;
  }
  .col_100 {
    max-width: 100%;
    margin: 20px 0px;
  }
}

@media (max-width: 1200px) {
  .popup-container_analysis {
    height: unset;
    padding: 20px 0px;
  }
  .col_3 h4 {
    position: relative;
    left: -133px;
  }
  .w_input {
    width: 100%;
  }

  .popup_analysis {
    width: 80%;
  }
  .baby_img {
    width: 302px;
    height: 379px;
    flex-shrink: 0;
  }
  .community_para {
    font-size: 30px;
    font-style: normal;
    width: 85%;
    line-height: 35px;
  }
  .Cuedwell_para {
    width: 80%;
  }
}
@media (max-width: 992px) {
  .ipsum_txt {
    font-size: 25px;

    line-height: 38px;
  }
  .global_challenge {
    padding: 40px 30px;

    column-gap: 29px;
  }
  .sed_txt {
    font-size: 11px;

    line-height: 24px;
  }
  .baby_img {
    width: 202px;
    height: 279px;
    flex-shrink: 0;
  }
  .mt_btn {
    margin-top: 20px;
  }
  .partner_flx {
    flex-direction: column;
    row-gap: 20px;
  }
  .sloware_img {
    border-left: 3px solid #fff;
    padding-left: 0px;
  }
}

@media (max-width: 767px) {
  .popup {
    width: 92%;
  }
  .baby_img {
    display: none;
  }
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
  .community_para {
    color: #000;

    font-size: 30px;
    font-style: normal;
    width: 90%;
    line-height: 38px;
  }
  .card_wrap {
    margin-top: 20px;
  }
  .w_50 {
    width: 100%;
  }
  .service_gap {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .Cuedwell_para {
    width: 100%;
  }
  .global_challenge {
    padding: 40px 30px;
    column-gap: 29px;
    flex-direction: column;
    row-gap: 40px;
  }
  .global_txt {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 574px) {
  .circle_flx {
    margin-left: 10px;
  }
  .footer_h {
    height: unset;
  }
  .form_flx {
    display: block;
  }
  .flx_end {
    display: flex;
    justify-content: end;
  }

  .popup_analysis {
    width: 94%;
  }
  .success_stories {
    background: #fffbe9;
    padding: 40px 15px;
  }
  .d_flx {
    column-gap: 11px;
  }
  .ipsum_txt {
    font-size: 17px;
    line-height: 24px;
  }
  .sed_txt {
    font-size: 10px;
    line-height: 20px;
  }
  .footer-col {
    width: 100%;
  }
  .gap_btn {
    column-gap: 20px;
    justify-content: end;
  }
  .cmn_gap {
    padding: 10px 15px;
  }
  .person_img {
    height: 188px;
  }
  .nav_flx {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.share_img_pos {
  position: relative;
  top: 5px;
}

/* ////// */
/* 4/03 */

.img_relative {
  position: relative;
}

.img_relative::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.div_three_all {
  width: 30%;
  border-radius: 20px 20px 0px 0px;
  border: 1px solid #b3b5b6;
}
.three_flx {
  margin: 20px 0px;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
  padding: 25px 0px 25px 0px;
}
.lorem_weight {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  padding: 10px 10px;
}
.lorem_bold {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 10px 0px;
}
.img_pot_img img {
  width: 100%;
}

/* .public_div {
  position: absolute;
  top: 50%;
  left: 65%;
} */
.home_img img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.pos_abs {
  position: absolute;
  top: 0;
  left: 50%;
  top: 40%;
}
.pos_img {
  position: relative;
}
.red_border {
  width: 92%;
  height: 30px;
  background-color: red;
  display: inline-flex;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.lorem_one,
.lorem_three {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 150% */
}
.lorem_two {
  color: #000;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 63px */
  letter-spacing: 0.45px;
}

.img_two {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  column-gap: 70px;
  row-gap: 30px;
  padding: 70px 0px 70px 0px;
}
.w_div,
.w_div_two {
  width: 45%;
  /* position: absolute; */
}
.img_div {
  margin-top: -20px;
  width: 100%;
}
.img_div img {
  width: 100%;
}
@media (max-width: 992px) {
  .div_three_all {
    width: 48%;
  }
  .w_div,
  .w_div_two {
    width: 40%;
  }
  .pos_abs {
    position: absolute;
    top: 0;
    left: 20%;
    top: 50%;
  }
}
@media (max-width: 623px) {
  .div_three_all,
  .w_div,
  .w_div_two {
    width: 100%;
  }
}
