.mobile_span1 {
  position: absolute;
  border-radius: 0px 7px 7px 0px;
  background: #f4f6fa;
  display: inline-block;
  padding: 11.2px 13px;
  top: 0.9px;
  left: 2px;
  color: #4c4c4c;
  font-family: Lato;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
.date_input1 {
  display: block;
  line-height: 35px;
  height: 45px;
  padding-left: 10px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fff;
  width: 207%;
  padding-right: 10px;
  margin-bottom: 15px;
}
