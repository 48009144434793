body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto","Inter","Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*  */
/*  */
/*  */
/*  */
/*  */

/* PHONE LOGIN AND MAIL LOGIN AND POPUP's */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Inter");

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  /* font-family: 'Poppins', sans-serif; */
  font-family: "Inter", sans-serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.login_page {
  background-color: #ffffff;
}
.login_form {
  padding-top: 50px;
  width: 70%;
}

.left_side_lp {
  flex: 0 0 45%;
}
.right_side_lp {
  flex: 0 0 55%;
}
.lp_right_text {
  color: #fff;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  margin-top: 30px;
  width: 75%;
  padding-left: 20px;
}
.logo_area {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
.logo_area span {
  color: #00a86b;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
}

.cus_row {
  display: flex;
  flex-direction: column;
}
.form_group label {
  color: #546670;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 5px;
}
.form_group input {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 13px 15px;
  background: #fff;
  color: #000;
  font-size: 14px;
  font-weight: 400;
}
.form_group {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}
.fp_text {
  color: #00a1ff;
  font-size: 12px;
  font-weight: 400;
  padding-top: 12px;
}
.btn_submit {
  border-radius: 4px;
  background: #00a86b;
  padding: 11px 24px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 24px;
  border: none;
  cursor: pointer;
}
.text_center >p {
  text-align: center;
  width: 100%;
}
.l_pn a {
  color: #00a86b;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}
.l_pn {
  margin-top: 24px;
}
.termspolicy_text {
  margin-top: 24px;
  color: #546670;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}
.termspolicy_text a {
  color: #00a1ff;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}
.or_text {
  color: #546670;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  display: block;
  margin-top: 40px;
}
.btn_google_sign {
  margin-top: 30px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  color: #a6a6a6;
  font-size: 16px;
  font-weight: 600;
  padding: 11px 11px;
  text-align: center;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn_google_sign span {
  padding-left: 7px;
}
.rights_text {
  margin-top: 50px;
  color: #c2c2c2;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  display: block;
  text-align: center;
}
.m_cus {
  margin: 20px;
  height: 100%;
}
.right_side_inner_box {
  background-color: #00a86b;
  border-radius: 20px;
  padding: 20px;
  height: 100%;
}
.lngr_circle {
  height: 100%;
}
.inner_blur {
  border-radius: 16px;
  border: 1px solid #fff;
  background: rgba(183, 233, 246, 0.25);
  backdrop-filter: blur(13px);
  height: 100%;
  position: relative;
}
.inner_img {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.inner_img img {
  position: absolute;
  right: 0;
  bottom: 0;
  object-fit: contain;
}
.ls_inner {
  padding: 40px 60px;
}
.mobile_input {
  padding-left: 65px;
}
.check_mail {
  color: #546670;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 15px 0px;
}
.w_input {
  width: 48%;
}

@media (max-width: 1200px) {
  .login_form {
    width: 100%;
  }

  .left_side_lp {
    flex: 0 0 50%;
  }
  .right_side_lp {
    flex: 0 0 50%;
  }

  .w_input {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .login_form {
    width: 100%;
  }
  .main_container_lp {
    flex-direction: column-reverse;
  }
  .lp_right_text {
    font-size: 24px;
    width: 60%;
  }

  .inner_img img {
    position: relative;
  }
}
/* 
 */
/* 
  /* */
/*  */

#popup {
  display: none;
}

#popup_share,
#popup_analysis {
  display: none;
}

.popup-container_social,
.popup-container_analysis {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: rgb(96 95 127 / 70%);
  position: absolute;
  top: 0;
  left: 0;
}

.popup-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: rgb(96 95 127 / 70%);
  position: absolute;
  top: 0;
  left: 0;
}

.popup {
  border-radius: 20px;
  background: #fff;
  padding: 20px 28px;
  width: 50%;
}

.popupmain {
  border-radius: 20px;
  background: #fff;
  padding: 20px 38px;
  width: 33%;
}

.popup_social {
  border-radius: 20px;
  background: #fff;
  padding: 20px 28px;
  width: 25%;
}

.popup_analysis {
  border-radius: 20px;
  background: #fff;
  padding: 20px 28px;
  width: 50%;
}

.close-popup {
  display: flex;
  justify-content: flex-end;
}

.close-popup a {
  font-size: 16px;

  color: #000;
  padding: 5px 10px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 10px;
  display: inline-block;
}

.popup > h2 {
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.popup > p {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.popup-btn {
  display: inline-block;

  margin: 10px 0px;
  transition: 0.2s all ease-in;
  display: inline-flex;
  padding: 11px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;

  border: transparent;
  cursor: pointer;
}

.main_container_lp {
  display: flex;
  flex-wrap: wrap;
}

.main_container {
  margin: 0 auto;
  max-width: 1366px;
}

.container {
  max-width: 1170px;
  margin: auto;
}

.accordion-container {
  margin-bottom: 20px;
  border: 1px solid#F7F7F7;
  background-color: #F7F7F7;
  border-radius: 8px;
}

.analyse_all {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.analyse_data {
  color: #000;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
}

.close-popup {
  display: flex;
  justify-content: flex-end;
}

.close-popup a {
  font-size: 16px;

  color: #000;
  padding: 5px 10px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 10px;
  display: inline-block;
}

.input_flx {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
}

.w_input {
  width: 48%;
}

.confirm_star {
  color: #ef2323;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  display: inline-block;
  margin-bottom: 10px;
}

.label_gender {
  color: #637381;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.dropdown-select {
  position: relative;
  height: 35px;
  cursor: pointer;
}

.dropdown-select .select-box {
  display: none !important;
}

.dropdown-select span.selection {
  display: block;
  line-height: 35px;
  padding-left: 10px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
}

.dropdown-select span.right-icon {
  position: absolute;
  overflow: hidden;
  top: 8px;
  right: 8px;
  bottom: 0;
}

.dropdown-select span.right-icon .fa {
  color: #000;
  font-size: 14px;
  padding-right: 6px;
  padding-left: 6px;
}

.dropdown-select ul {
  list-style: none;
  margin: 0 0 0 0;
  padding: 0;
}

.dropdown-select ul.list-items {
  visibility: hidden;
  background-color: #f0f0f3;
  transition: 0.2s all ease-in-out;
  padding-bottom: 2px;
  margin-bottom: 2px;
  position: relative;
  z-index: 999;
}

.dropdown-select ul.list-items.open {
  visibility: visible;
}

.dropdown-select ul.list-items li {
  height: 35px;
  line-height: 45px;
  background-color: #fff;
  padding-left: 10px;
  margin: 0 2px;
}

.dropdown-select ul.list-items li.active {
  position: relative;
}

.dropdown-select ul.list-items li.active:before {
  content: " ";
  width: 5px;
  height: 100%;
  position: absolute;
  background: #0f57d6;
  left: 0;
}

.select_form:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.select_form {
  color: #919191;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #d9d9d9;
  padding: 10px 10px;
  border-radius: 6px;
  flex: 0 0 20%;
}

.entries_select {
  color: #919191;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #d9d9d9;
  padding: 4px 10px;
  border-radius: 15px;
  width: 52px;
}

.entries_select span {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}

.right_side_lp {
  flex: 0 0 55%;
}

.icon {
  transition: transform 0.3s ease;
}

.active .icon {
  transform: rotate(45deg);
}

.sort_icon {
  padding-left: 10px;
}

.w_input {
  width: 48%;
}

.w_inputsubmit {
  width: 25%;
}

.kg_div {
  position: relative;
}
.watsapp_label {
  color: #000;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  position: relative;
  top: -60px;
  left: 8px;
}
.kg_span {
  position: absolute;
  border-radius: 0px 7px 7px 0px;
  background: #f4f6fa;
  display: inline-block;
  padding: 7px 13px;
  top: 1px;
  right: 0.5px;
  color: #4c4c4c;
  font-family: Lato;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.cancel_analyze_btn {
  display: flex;
  justify-content: end;
  column-gap: 15px;
  margin: 20px 0px 0px 0px;
}

.btn_submit {
  border-radius: 4px;
  background: #00a86b;
  padding: 11px 24px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 24px;
  border: none;
  cursor: pointer;
}

.bg_cancel {
  background: #f4f6fa;
  color: #000;
}

.bg_analyze {
  background-color: #00a86b;
  color: #fff;
}

.date_input {
  display: inline-flex;
  line-height: 35px;
  padding-left: 10px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  width: 100%;
  padding-right: 10px;
}
.dropdown-options {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown-options div {
  padding: 8px;
  cursor: pointer;
}
.mobile_span {
  position: absolute;
  border-radius: 0px 7px 7px 0px;
  background: #f4f6fa;
  display: inline-block;
  padding: 6.2px 13px;
  top: 2.1px;
  left: 2px;
  color: #4c4c4c;
  font-family: Lato;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.date_input:focus-visible {
  outline: none;
}
.mobile_input {
  padding-left: 65px;
}

@media (max-width: 1200px) {
  .popup-container_analysis {
    height: unset;
    padding: 20px 0px;
  }

  .w_input {
    width: 100%;
  }

  .popup-container {
    height: unset;
    width: 100%;
    padding: 20px 0px;
  }

  .popup_analysis {
    width: 80%;
  }

  .popup_social {
    width: 44%;
  }

  .right_side_lp {
    flex: 0 0 50%;
  }
}

@media (max-width: 767px) {
  .popup_social {
    width: 60%;
  }

  .popup {
    width: 92%;
  }
}

@media (max-width: 574px) {
  .popup_social {
    width: 80%;
  }

  .popup_analysis {
    width: 94%;
  }
  .w_inputsubmit {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .select_form {
    flex: 0 0 100%;
  }

  .right_side_lp {
    flex: 0 0 50%;
  }
}

@media (max-width: 992px) {
  .w_inputsubmit {
    width: 40%;
  }
}

/* PHONE LOGIN AND MAIL LOGIN AND POPUP's*/

input[type="date"] {
    position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type="date"]:after {
    content: "\25BC"; 
    color: #555;
    padding: 0 5px;
}

/* change color of symbol on hover */
input[type="date"]:hover:after {
    color: #bf1400;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
}

/* adjust clear button */
input[type="date"]::-webkit-clear-button {
    z-index: 1;
}

.bubble::after {
    content: '';
    border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 100%;
    background-color: #0f0f10;
    height: 85%;
}